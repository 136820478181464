import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Error from '../Error/Error';
import Loader from '../../../Loader';

const Specimen = () => {

  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  });


  

  
  useEffect(() => { 
    getSeoData();
    //getCountryList();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  //---------------------------- for SEO ---------
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.specimenSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"specimen"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  //---------------------------- for SEO ---------
//---------------------------- for form ---------

const [specimanData, setspecimanData] = useState({
    
  schoolName:"",
  principalName:"",
  school_email:"",
  schoolLandline:"",
  country:"",
  state:"",
  city:"",
  schoolAddress:"",
  schoolPin:"",
  board:"",
  specimenRequired:"",
  designation:"",
  email:"",
  mobile:"",
  landLine:"",
  subjectTought:"",
  class:"",
  bookName:"",
  DOB:"",
  pin:"",
  residentialAddress:"",
  
  
  schoolICard:"",
  msg:"",
  status:"",
  errMsg:""
})

function changespecimanData(value, type){
  setspecimanData((prev)=>{

    return {...prev, [type]:value}
  })
  console.log(value.name)
}
  function shareResume(e){
    e.preventDefault();


    setspecimanData((prev)=>{
      return {...prev, status:'loading', errMsg:""}
    })


    const formData = new FormData();
  
    formData.append("schoolName", specimanData.schoolName);
	formData.append("principalName", specimanData.principalName);
	formData.append("school_email", specimanData.school_email);
	formData.append("schoolLandline", specimanData.schoolLandline);
	formData.append("country", specimanData.country);
	formData.append("state", specimanData.state);
	formData.append("city", specimanData.city);
	formData.append("schoolAddress", specimanData.schoolAddress);
	formData.append("schoolPin", specimanData.schoolPin);
	formData.append("board", specimanData.board);
	formData.append("specimenRequired", specimanData.specimenRequired);
	formData.append("designation", specimanData.designation);
	formData.append("email", specimanData.email);
	formData.append("mobile", specimanData.mobile);
	formData.append("landLine", specimanData.landLine);
	formData.append("subjectTought", specimanData.subjectTought);
	formData.append("class", specimanData.class);
	formData.append("bookName", specimanData.bookName);
	formData.append("DOB", specimanData.DOB);
	formData.append("pin", specimanData.pin);
	formData.append("residentialAddress", specimanData.residentialAddress);
	
	
    formData.append("message", specimanData.msg);
    formData.append("schoolICard", specimanData.schoolICard);


    fetch(apiBaseURL+apiURL.specimanSave,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
      },
      body:formData
    } )
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        if(result.status=="1"){
          setspecimanData((prev)=>{
            return {
              ...prev, 
              
              schoolName:"",
			  principalName:"",
			  school_email:"",
			  schoolLandline:"",
			  country:"",
			  state:"",
			  city:"",
			  schoolAddress:"",
			  schoolPin:"",
			  board:"",
			  specimenRequired:"",
			  designation:"",
			  email:"",
			  subjectTought:"",
			  class:"",
			  bookName:"",
			  DOB:"",
			  pin:"",
			  residentialAddress:"",
			  
			  
			  
              schoolICard:"",
              msg:"",
              status:'success', 
              errMsg:"Successfully submitted!"
            }
          })
          document.getElementById("scchIdFile").value = "";
        } else {
          setspecimanData((prev)=>{
            return {...prev, status:'error', errMsg:result.message}
          })
        }
      })
      .catch( (error)=>{
        console.log(error)
        setspecimanData((prev)=>{
          return {...prev, status:'error', errMsg:"network error! try again."}
        })
      })





  }
  
//------------------------------------------  
  
  
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
        <h1 className="invi-authors"> SPECIMEN </h1>
        <div className='box-invi'>
          <Row>
            <Col md={12} data-aos="zoom-in">
              <p> We , at Rachna Sagar, are committed to empower the imagination of the learners and encourage them to soar high. To enable the 
              learners to grasp and prepare for examination so that they can achieve their best, we have published  the latest edition of our
              books and practice material in compliance with the latest syllabus and new examination pattern. Digital online support with 
              these books like Test Generator will help teachers in generating test papers.<br/><br/>
              We request you for your favorable recommendation and look forward to your valued order. We welcome your valuable suggestions
              and  assure you of our best services and cooperation.<br/>
              <b>Rachna Sagar provides complimentary specimen to all respected teachers. Please fill and submit the form 
              for your specimen copy.</b>
              </p>
            </Col>
          </Row>
        </div>
    </Container>
  </div>
  <Container>
    <div className='invitation-form'>
      <div className='specimen-bg'>
      <h2 className='invifrom-hedi'>Description (Institute / School Details) :</h2>
        
      
      
    {/* ===================================================== */}






    <form className="" onSubmit={shareResume}>


                <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <label className="login-hedi">School/Institute Name *</label>
                        <input type="" className="form-control feed-mar" id="" placeholder="School/Institute Name" 
					  					      value={specimanData.schoolName} onChange={(e)=>{changespecimanData(e.target.value, "schoolName")}}/>
                      </div>
                  </div>
                </div>
				
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                  <label className="login-hedi">Name of the Principal  *</label>
                  <input type="" className="form-control feed-mar" id="" placeholder="Name of the Principal " 
                                  value={specimanData.principalName} onChange={(e)=>{changespecimanData(e.target.value, "principalName")}}/>
                  
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="login-hedi">Email *</label>
                    <input type="" className="form-control feed-mar" id="" placeholder="Email Id " 
                                  value={specimanData.school_email} onChange={(e)=>{changespecimanData(e.target.value, "school_email")}}/>
                  </div>
              	</div>
				
				<div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">Landline</label>
					<input type="" className="form-control feed-mar" id="" placeholder="Landline " 
                                  value={specimanData.schoolLandline} onChange={(e)=>{changespecimanData(e.target.value, "schoolLandline")}}/>
				  </div>
			  	</div>
				
				<div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">Country *</label>
					<select className="form-control feed-mar form-select" value={specimanData.country} onChange={(e)=>{changespecimanData(e.target.value, 'country')}}>
						<option hidden value=""> --- Please Select --- </option>
						<option value="India">India</option>
						<option value="Nepal">Nepal</option>
						<option value="other">Other</option>
					  </select>
					  
					 
				  </div>
			  	</div>
				
				<div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">State *</label>
					<input type="" className="form-control feed-mar" id="" placeholder="State " 
                                  value={specimanData.state} onChange={(e)=>{changespecimanData(e.target.value, "state")}}/> 
				  </div>
			  	</div>
				
				<div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">City *</label>
					<input type="" className="form-control feed-mar" id="" placeholder="City " 
                                  value={specimanData.city} onChange={(e)=>{changespecimanData(e.target.value, "city")}}/>
				  </div>
			  </div>
			  
			  <div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">Institute / School Address *</label>
					<input type="" className="form-control feed-mar" id="" placeholder="School Address " 
                                  value={specimanData.schoolAddress} onChange={(e)=>{changespecimanData(e.target.value, "schoolAddress")}}/>
				  </div>
			  </div>
			  
			  <div className="col-md-6">
				  <div className="form-group">
					<label className="login-hedi">Pincode *</label>
					<input type="" className="form-control feed-mar" id="" placeholder="Pin code " 
                                  value={specimanData.schoolPin} onChange={(e)=>{changespecimanData(e.target.value, "schoolPin")}}/>
				  </div>
			  </div>

            </div>
			
			
			
			<div className="row">
			  <div className="col-md-12">
			  <div className="form-group">
				  <label  className='login-hedi'>Board *</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				  <span className="radio-inline" >
				  <input className="cheek" type="radio" value="cbse" checked={specimanData.board=='cbse'} onChange={(e)=>{changespecimanData(e.target.value, 'board')}}/>
				  CBSE
				  <input className="cheek" type="radio" value="icse/ise" checked={specimanData.board=='icse/ise'} onChange={(e)=>{changespecimanData(e.target.value, 'board')}}/>
				  ICSE / ISE &nbsp;&nbsp;
				  <input className="cheek" type="radio" value="state" checked={specimanData.board=='state'} onChange={(e)=>{changespecimanData(e.target.value, 'board')}}/>
				  State &nbsp;&nbsp;
				  <input className="cheek" type="radio" value="international" checked={specimanData.board=='international'} onChange={(e)=>{changespecimanData(e.target.value, 'board')}}/>
				  International  &nbsp;&nbsp;
				  <input className="cheek" type="radio" value="other" checked={specimanData.board=='other'} onChange={(e)=>{changespecimanData(e.target.value, 'board')}}/>
				  Other </span>
			  </div>
			  </div>
			  </div>
			  <h2 className='invifrom-hedi'>Contact Details :</h2>
			  <div className="row">
				<div className="col-md-12">
					<div className="form-group">
					  <label className="login-hedi">Specimen Required by *</label>
					  <input type="" className="form-control feed-mar" value={specimanData.specimenRequired} onChange={(e)=>{changespecimanData(e.target.value, 'specimenRequired')}} />
					</div>
				</div>
			  </div>
			  <div className="row">
				<div className="col-md-6">
					<div className="form-group">
					  <label className="login-hedi">Your Designation *</label>
					  <select className="form-control feed-mar form-select" value={specimanData.designation} onChange={(e)=>{changespecimanData(e.target.value, 'designation')}}>
						<option hidden value=""> --- Please Select --- </option>
						<option value="teacher">Teacher</option>
						<option value="school/admin">School / Admin</option>
						<option value="tutor">Tutor</option>
						<option value="other">Other</option>
					  </select>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					  <label className="login-hedi">Email *</label>
					  <input type="email" className="form-control feed-mar" value={specimanData.email} onChange={(e)=>{changespecimanData(e.target.value, 'email')}} />
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					  <label className="login-hedi"> Mobile *</label>
					  <input type="mobile" className="form-control feed-mar" value={specimanData.mobile} onChange={(e)=>{changespecimanData(e.target.value, 'mobile')}} />
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					  <label className="login-hedi"> Landline </label>
					  <input type="landline" className="form-control feed-mar" value={specimanData.landLine} onChange={(e)=>{changespecimanData(e.target.value, 'landLine')}} />
					</div>
				</div>
			</div>
			
			
			<div className='row'>
			  <div className="col-md-6">
				<div className="form-group">
				  <label className="login-hedi"> Subject Taught *</label>
				  <input type="subjectta" className="form-control feed-mar" value={specimanData.subjectTought} onChange={(e)=>{changespecimanData(e.target.value, 'subjectTought')}} />
				</div>
			  </div>
			  <div className="col-md-6">
				<div className="form-group">
				  <label className="login-hedi"> Class *</label>
				  <input type="subjectta" className="form-control feed-mar" value={specimanData.class} onChange={(e)=>{changespecimanData(e.target.value, 'class')}} />
				</div>
			  </div>
			</div>
			
			<div className="row">
			<div className="col-md-12">
				<div className="form-group">
				  <label className="login-hedi"> Name of the book *</label>
				  <input type="subjectta" className="form-control feed-mar" value={specimanData.bookName} onChange={(e)=>{changespecimanData(e.target.value, 'bookName')}} />
				</div>
			  </div>
			</div>
			
			<div className="row">
			<div className="col-md-6">
				<div className="form-group">
				  <label className="login-hedi"> Date of Birth *</label>
				  <input type="date" className="form-control feed-mar" value={specimanData.DOB} onChange={(e)=>{changespecimanData(e.target.value, 'DOB')}} />
				</div>
			  </div>
			  <div className="col-md-6">
				<div className="form-group">
				  <label className="login-hedi"> Pin Code *</label>
				  <input type="subjectta" className="form-control feed-mar" value={specimanData.pin} onChange={(e)=>{changespecimanData(e.target.value, 'pin')}} />
				</div>
			  </div>
			  <div className="col-md-12">
				<div className="form-group">
				  <label className="login-hedi"> Residential Address *</label>
				  <textarea className="form-control feed-mar" rows="2" value={specimanData.residentialAddress} onChange={(e)=>{changespecimanData(e.target.value, 'residentialAddress')}}></textarea>
				</div>
			  </div>
			</div>
			
              
                
                
                
                <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="login-hedi">  &#10132; Upload your School ID Card / Visiting Card/ Principal`s Authorization: * <br/>  
                                                    &#10132; The file size should not be more than 1MB *
                    </label>
                    <div> 
                      <input type="file" id="scchIdFile"  className="form-control-file" 
                      onChange={(e)=>{changespecimanData(e.target.files[0], "schoolICard")}}/>
                      
                    </div>
                  </div>
                </div> 
              </div>
                
                
                
                
                

                <div className="row">
                  
                  <div className="col-md-12"> 
                  {(specimanData.status=="error" || specimanData.status=="success")&&
                    <div className="review-success" style={specimanData.status=="error"?{backgroundColor:'#f2a899'}:null}>
                        <div className="col-md-10 col-10"> {specimanData.errMsg} </div>
                    </div>
                  }
                    <div className="right-login">
                    {specimanData.status=="loading"?
                      <div style={{position:'relative', height:'80px'}}>
                        <Loader width="50px" height="50px" backgroundColor="transparent"/>
                      </div>: 
                      <button className="forget_btn" type="submit"> Submit </button>
                    }
                     
                    </div>
                  </div>
                </div>              
              </form>












  {/* ====================================== */}




      </div>
  </div>
  </Container>
    {/* {resData.status=="error"&&
      <Error cancle={canclePopup} msg={resData.msg} fun={resData.fun}/>
    } */}
  <Footer/>
  </>
  );
};
export default Specimen;