import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import RelatedBooks from "./RelatedBooks";
import SuggestedBooks from './SuggestedBooks';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button'

import CartPopup from '../../CartPopup';
import { apiURL, apiBaseURL, apiKey, BOOK_VARIENTS } from '../../../constant';
import Loader from '../../../Loader';
import { Contaxt } from '../../../Store';
import ZoomImg from './ZoomImg';
import Error from '../Error/Error'
import Helmet from 'react-helmet';
import Modal from "react-modal";
// import VideoPopup from './VideoPopup';



Modal.setAppElement("#root"); // important for accessibility


const Products = () => {

  const [showPopupCart, setPopupCart] = useState({ status: false, addingToCart: false });
  const [bookData, setBookData] = useState({ isLoading: true, data: {}, detail: {}, selectedVarient: {}, productStatus: {} });
  const [cartData, setCartData] = useState({ isLoading: true, items: [], total: 0, discount: 0, subtotal: 0 });
  const [relatedBooks, setRelatedBooks] = useState([]);
  const [suggestedBooks, setSuggestedBooks] = useState([]);
  const [error, setError] = useState({ status: false, msg: "" });
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {},
    isDone: false,
  });
  const [reviewData, setReviewData] = useState({
    reviewList: [],
    reviewStars: {}
  })
  const { userData, offlineCartData, addItemOnCart, updateItemOnCart, removeItemFromCart, setCartItemNum } = useContext(Contaxt);
  const navigate = useNavigate();
  const [queryString] = useSearchParams();
  const bookId = queryString.get('id');

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to open modal
  // const openModal = () => {
  //   setModalIsOpen(true);
  // };

  // // Function to close modal
  // const closeModal = () => {
  //   setModalIsOpen(false);
  // };
  let location = useLocation();


  var ProductTypeStr = queryString.get('type');
  //console.log(ProductTypeStr);

  console.log(location.pathname);

  let byDefaultProductType = "p_book";
  function isEmpty(ProductTypeStr) {
    return (ProductTypeStr === undefined || ProductTypeStr == null || ProductTypeStr.length <= 0) ? byDefaultProductType : ProductTypeStr;
  }
  let ProductType = isEmpty(ProductTypeStr);

  //console.log("BT--"+ProductType);


  useEffect(() => {
    if (!userData.loginStatus) {
      getCartList()
    }
  }, [offlineCartData])
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0)
    setTimeout(() => {
      resetData();
      getBookDetail();
      getReviewData();
      // if(relatedBooks.length==0)
      getRelatedBooks();
      getSuggestedBooks();

    }, 200)
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, [bookId]);


  function resetData() {
    setPopupCart({ status: false, addingToCart: false })
    setBookData({ isLoading: true, data: {}, detail: {}, selectedVarient: {}, productStatus: {} })
    setRelatedBooks([])
    setSuggestedBooks([])
    setError({ status: false, msg: "" })
  }

  function removeError() {
    navigate('/')
    // setError({status:false, msg:""})
  }

  function isOffileAddedToCart(bookId, type) {
    // console.log(bookId, type);
    for (let item of offlineCartData.items) {
      // console.log(item.ProductId, item.Product_Type)
      if (item.ProductId == bookId && item.Product_Type == type) {
        return true;
      }
    }
    return false;
  }
  function reloadBookDetail() {
    resetData();
    getBookDetail();
  }

  function getBookDetail() {

    fetch(apiBaseURL + apiURL.bookDetail, {
      method: 'POST',
      headers: {
        'rskey': apiKey,
        'utoken': userData.token
      },
      body: JSON.stringify({ "productId": bookId })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status == "1") {

          setBookData((prev) => {
            //   let selectedVarient = {
            //     description:result.product_details?.book_desc,
            //     bookPoint:result.product_details?.desp_point_book,
            //     price:result.products?.book_price,
            //     bookMrp:result.products?.book_mrp,
            //     perDis:result.products?.book_perDiscount,
            //     weigth:result.products?.book_weight,
            //     bookType:BOOK_VARIENTS.pBook,
            //     isAddedToCart:addedToCart,
            //     isInWishlist:result.user_data?.paperbook_wishlist=="1"?true:false
            //   } 
            //   if(queryString.get('type')==BOOK_VARIENTS.gBook){
            //     selectedVarient = {
            //       description:result.product_details?.games_desc,
            //       bookPoint:result.product_details?.desp_point_game,
            //       price:result.products?.games_price,
            //       bookMrp:result.products?.games_mrp,
            //       perDis:result.products?.games_perDiscount,
            //       weigth:undefined,
            //       bookType:BOOK_VARIENTS.gBook,
            //       isAddedToCart:result.user_data?.game_addToCart=="1"?true:false,
            //       isInWishlist:result.user_data?.game_wishlist=="1"?true:false
            //     } 
            //   }
            return {
              ...prev,
              isLoading: false,
              data: result.products,
              detail: result.product_details,
              productStatus: result.user_data,
              // selectedVarient:selectedVarient
            }
          });
          selectInitalVarient(ProductType, result)
          setSeoData({
            title: result.products?.meta_title,
            description: result.products?.meta_description,
            keywords: result.products?.meta_keyword,
            ogTags: result.products?.Og_Tag,
            twitterTags: result.products?.Twitter_Tag,
            isDone: true,
          })
        } else {
          setBookData((prev) => {
            return { ...prev, isLoading: false }
          });
          setError({ status: true, msg: result.message })
        }
      })
      .catch((error) => {
        console.log(error)
        setBookData((prev) => {
          return { ...prev, isLoading: false }
        })
        setError({ status: true, msg: "Network Error!" })
      });
  }

  function getReviewData() {


    fetch(apiBaseURL + apiURL.reviewStars, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
      },
      body: JSON.stringify({ "bookid": bookId })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result)
          setReviewData((prev) => {
            return { ...prev, reviewStars: result.reviews_breakdown_data }
          })
        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      });

    fetch(apiBaseURL + apiURL.reviewList, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
      },
      body: JSON.stringify({ "bookid": bookId })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status == "1") {
          setReviewData((prev) => {
            return { ...prev, reviewList: result.reviews_data }
          })
        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      });
  }
  function getRelatedBooks() {
    fetch(apiBaseURL + apiURL.relatedBooks, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
      },
      body: JSON.stringify({ "productId": bookId, "parameter": ProductType })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result)
          // console.log(result.user_data.paperbook_addToCart)
          // console.log(result.related_products)
          setRelatedBooks(result.related_products);
          // if(relatedBooks.length==0) {

          // } else {
          //   setRelatedBooks([])
          // }
        } else {
          setRelatedBooks([])
        }
      })
      .catch((error) => {
        console.log(error)
        setRelatedBooks([])
      });
  }


  function getSuggestedBooks() {
    fetch(apiBaseURL + apiURL.suggestedBooks, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
      },
      body: JSON.stringify({ "productId": bookId, "parameter": ProductType })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result)   suggestedBooks
          // console.log(result.user_data.paperbook_addToCart)
          console.log(bookId)
          //setSuggestedBooks(result.suggested_products);
          //setSuggestedBooks(result.suggested_products);

          setSuggestedBooks(result.suggested_products);

          //if(suggestedBooks.length==0) {            
          //} 
          //else {
          //   setRelatedBooks([])
          // }
        } else {
          setSuggestedBooks([])
        }
      })
      .catch((error) => {
        console.log(error)
        setSuggestedBooks([])
      });
  }

  function selectInitalVarient(type, bookData) {
    let selectedVarient = {}
    if (type == BOOK_VARIENTS.pBook) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.pBook)
      } else {
        addedToCart = bookData.user_data?.paperbook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.book_desc,
        bookPoint: bookData.product_details?.desp_point_book,
        price: bookData.products?.book_price,
        bookMrp: bookData.products?.book_mrp,
        perDis: bookData.products?.book_perDiscount,
        weigth: bookData.products?.book_weight,
        bookStock: bookData.products?.paperbook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.paperbook_wishlist == "1" ? true : false,
      }
    } else if (type == BOOK_VARIENTS.eBook) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.eBook)
      } else {
        addedToCart = bookData.user_data?.ebook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.ebook_desc,
        bookPoint: bookData.product_details?.desp_point_eBook,
        price: bookData.products?.ebook_price,
        bookMrp: bookData.products?.ebook_mrp,
        perDis: bookData.products?.ebook_perDiscount,
        weigth: undefined,//bookData.products?.book_weight,
        bookStock: bookData.products?.eBook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.ebook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.peBook) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.peBook)
      } else {
        addedToCart = bookData.user_data?.paperbook_ebook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.paperbook_ebook_desc,
        bookPoint: bookData.product_details?.desp_point_paperbook_ebook,
        price: bookData.products?.paperbook_ebook_price,
        bookMrp: bookData.products?.paperbook_ebook_mrp,
        perDis: bookData.products?.paperbook_ebook_perDiscount,
        weigth: undefined,//bookData.products?.book_weight,
        bookStock: bookData.products?.paperbook_ebook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.paperbook_ebook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.ieBook) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.ieBook)
      } else {
        addedToCart = bookData.user_data?.InteractiveEbook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.interactiveEbook_desc,
        bookPoint: bookData.product_details?.desp_point_ieBook,
        price: bookData.products?.interactiveEbook_price,
        bookMrp: bookData.products?.interactiveEbook_mrp,
        perDis: bookData.products?.interactiveEbook_perDiscount,
        weigth: undefined,//bookData.products?.book_weight,
        bookStock: bookData.products?.InteractiveEbook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.InteractiveEbook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.cdDisk) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.cdDisk)
      } else {
        addedToCart = bookData.user_data?.cd_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.cd_desc,
        bookPoint: bookData.product_details?.desp_point_cd,
        price: bookData.products?.cd_price,
        bookMrp: bookData.products?.cd_mrp,
        perDis: bookData.products?.cd_perDiscount,
        weigth: undefined, //bookData.products?.book_weight,
        bookStock: bookData.products?.cd_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.cd_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.gBook) {
      let addedToCart = false;
      if (bookData.user_data.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.products.productId, BOOK_VARIENTS.gBook)
      } else {
        addedToCart = bookData.user_data?.game_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.product_details?.games_desc,
        bookPoint: bookData.product_details?.desp_point_game,
        price: bookData.products?.games_price,
        bookMrp: bookData.products?.games_mrp,
        perDis: bookData.products?.games_perDiscount,
        weigth: undefined, //bookData.products?.book_weight,
        bookStock: bookData.products?.games_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.user_data?.game_wishlist == "1" ? true : false
      }
    }
    setBookData((prev) => {
      return { ...prev, selectedVarient: selectedVarient }
    })
  }
  function selectVarient(type) {
    let selectedVarient = {}
    if (type == BOOK_VARIENTS.pBook) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.pBook)
      } else {
        addedToCart = bookData.productStatus?.paperbook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.book_desc,
        bookPoint: bookData.detail?.desp_point_book,
        price: bookData.data?.book_price,
        bookMrp: bookData.data?.book_mrp,
        perDis: bookData.data?.book_perDiscount,
        weigth: bookData.data?.book_weight,
        bookStock: bookData.data?.paperbook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.paperbook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.eBook) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.eBook)
      } else {
        addedToCart = bookData.productStatus?.ebook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.ebook_desc,
        bookPoint: bookData.detail?.desp_point_eBook,
        price: bookData.data?.ebook_price,
        bookMrp: bookData.data?.ebook_mrp,
        perDis: bookData.data?.ebook_perDiscount,
        weigth: undefined,//bookData.data?.book_weight,
        bookStock: bookData.data?.eBook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.ebook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.peBook) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.peBook)
      } else {
        addedToCart = bookData.productStatus?.paperbook_ebook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.paperbook_ebook_desc,
        bookPoint: bookData.detail?.desp_point_paperbook_ebook,
        price: bookData.data?.paperbook_ebook_price,
        bookMrp: bookData.data?.paperbook_ebook_mrp,
        perDis: bookData.data?.paperbook_ebook_perDiscount,
        weigth: undefined,//bookData.data?.book_weight,
        bookStock: bookData.data?.paperbook_ebook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.paperbook_ebook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.ieBook) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.ieBook)
      } else {
        addedToCart = bookData.productStatus?.InteractiveEbook_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.interactiveEbook_desc,
        bookPoint: bookData.detail?.desp_point_ieBook,
        price: bookData.data?.interactiveEbook_price,
        bookMrp: bookData.data?.interactiveEbook_mrp,
        perDis: bookData.data?.interactiveEbook_perDiscount,
        weigth: undefined,//bookData.data?.book_weight,
        bookStock: bookData.data?.InteractiveEbook_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.InteractiveEbook_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.cdDisk) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.cdDisk)
      } else {
        addedToCart = bookData.productStatus?.cd_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.cd_desc,
        bookPoint: bookData.detail?.desp_point_cd,
        price: bookData.data?.cd_price,
        bookMrp: bookData.data?.cd_mrp,
        perDis: bookData.data?.cd_perDiscount,
        weigth: undefined, //bookData.data?.book_weight,
        bookStock: bookData.data?.cd_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.cd_wishlist == "1" ? true : false
      }
    } else if (type == BOOK_VARIENTS.gBook) {
      let addedToCart = false;
      if (bookData.productStatus.login_status == "0") {
        addedToCart = isOffileAddedToCart(bookData.data.productId, BOOK_VARIENTS.gBook)
      } else {
        addedToCart = bookData.productStatus?.game_addToCart == "1" ? true : false;
      }
      selectedVarient = {
        description: bookData.detail?.games_desc,
        bookPoint: bookData.detail?.desp_point_game,
        price: bookData.data?.games_price,
        bookMrp: bookData.data?.games_mrp,
        perDis: bookData.data?.games_perDiscount,
        weigth: undefined, //bookData.data?.book_weight,
        bookStock: bookData.data?.games_stock,
        bookType: type,
        isAddedToCart: addedToCart,
        isInWishlist: bookData.productStatus?.game_wishlist == "1" ? true : false
      }
    }
    setBookData((prev) => {
      return { ...prev, selectedVarient: selectedVarient }
    })
  }

  function getBookCartStatusName(type) {
    if (type == BOOK_VARIENTS.pBook) {
      return "paperbook_addToCart"
    } else if (type == BOOK_VARIENTS.eBook) {
      return "ebook_addToCart"
    } else if (type == BOOK_VARIENTS.ieBook) {
      return "InteractiveEbook_addToCart"
    } else if (type == BOOK_VARIENTS.cdDisk) {
      return "cd_addToCart"
    } else if (type == BOOK_VARIENTS.gBook) {
      return "game_addToCart"
    }
    return "";
  }
  function getBookWishlistStatusName(type) {
    if (type == BOOK_VARIENTS.pBook) {
      return "paperbook_wishlist"
    } else if (type == BOOK_VARIENTS.eBook) {
      return "ebook_wishlist"
    } else if (type == BOOK_VARIENTS.ieBook) {
      return "InteractiveEbook_wishlist"
    } else if (type == BOOK_VARIENTS.cdDisk) {
      return "cd_wishlist"
    } else if (type == BOOK_VARIENTS.gBook) {
      return "game_wishlist"
    }
    return "";
  }
  function addToCart() {
    // console.log(userData)
    if (!userData.loginStatus) {
      let productType = bookData.selectedVarient.bookType;
      addItemOnCart(bookData.data, productType)
      setPopupCart({ status: true, addingToCart: false })
      getCartList();
      setBookData((prev) => {

        let selectedVarient = { ...prev.selectedVarient, isAddedToCart: true }
        let productStatus = { ...prev.productStatus, [getBookCartStatusName(productType)]: "1" }
        return { ...prev, selectedVarient, productStatus }
      })

    } else {
      let productType = bookData.selectedVarient.bookType;
      const sendData = {
        "bookId": `${bookData.data.productId}`,
        "product_type": productType,
        "quntity": 1
      }
      setPopupCart({ status: false, addingToCart: true })

      fetch(apiBaseURL + apiURL.addToCart, {
        method: 'POST',
        headers: {
          'rskey': 'rspl',
          'utoken': userData.token,
        },
        body: JSON.stringify(sendData)
      })
        .then(response => response.json())
        .then((result) => {
          // console.log(result)
          if (result.status == "1") {
            setPopupCart({ status: true, addingToCart: false })
            getCartList();
            setBookData((prev) => {

              let selectedVarient = { ...prev.selectedVarient, isAddedToCart: true }
              let productStatus = { ...prev.productStatus, [getBookCartStatusName(productType)]: "1" }
              return { ...prev, selectedVarient, productStatus }
            })
            setCartItemNum(result.Total_Items)
          } else {
            // show error
            setPopupCart({ status: false, addingToCart: false })
          }
        })
        .catch((error) => {
          console.log(error)
          setPopupCart({ status: false, addingToCart: false })
          //  show error
        });

    }
  }

  function addToWishlist(action) {
    // console.log(userData)
    if (!userData.loginStatus) {
      // setPopupCart({status:true, addingToCart:false})
      let url = btoa(`bookdetail/${bookId}?type=${ProductType}`);
      navigate('/account/login/' + url)
    } else {
      let productType = bookData.selectedVarient.bookType;
      const sendData = {
        "bookId": `${bookData.data.productId}`,
        "product_type": productType,
        "action": action
      }
      setWishlistLoading(true);

      fetch(apiBaseURL + apiURL.addToWishList, {
        method: 'POST',
        headers: {
          'rskey': 'rspl',
          'utoken': userData.token,
        },
        body: JSON.stringify(sendData)
      })
        .then(response => response.json())
        .then((result) => {
          // console.log(result)
          setWishlistLoading(false);
          if (result.status == "1") {
            setBookData((prev) => {
              let isInWishlist = true;
              if (result.message == "Removed from Wishlist") {
                isInWishlist = false;
              }
              let selectedVarient = { ...prev.selectedVarient, isInWishlist }
              let productStatus = { ...prev.productStatus, [getBookWishlistStatusName(productType)]: "1" }
              return { ...prev, selectedVarient, productStatus }
            })
          } else {
            // show error
          }
        })
        .catch((error) => {
          console.log(error)
          setWishlistLoading(false);
          //  show error
        });

    }
  }

  async function getCartList() {

    if (!userData.loginStatus) {

      setCartData((prev) => {
        return {
          ...prev,
          isLoading: false,
          items: offlineCartData.items?.map(item => ({ ...item, isUpdating: false })),
          total: offlineCartData.totalMrp,
          discount: offlineCartData.totalDiscount,
          subtotal: offlineCartData.totalAfterDiscount,
        }
      });

      return;
    }
    try {
      const response = await fetch(apiBaseURL + apiURL.cartList, {
        method: "POST",
        headers: { 'rskey': "rspl", 'utoken': userData.token }
      })
      let result = await response.json();
      console.log(result)
      if (result.status == "1") {
        setCartData((prev) => {
          return {
            ...prev,
            isLoading: false,
            items: result.cart_items?.map(item => ({ ...item, isUpdating: false })),
            total: result.Total_MRP,
            discount: result.Total_Discount,
            subtotal: result.SubTotal
          }
        });
      } else {
        setCartData((prev) => {
          return {
            ...prev,
            isLoading: false,
          }
        });
      }
    } catch (err) {
      console.log(err);
      setCartData((prev) => {
        return {
          ...prev,
          isLoading: false,
        }
      });
    }
  }

  function updateProduct(bookId, bookType, action, index) {

    if (!userData.loginStatus) {
      updateItemOnCart(bookId, bookType, action)
      return;
    }
    setCartData((prev) => {
      let items = [...prev.items];

      if (items[index] != undefined && items[index].ProductId == bookId) {
        items[index].isUpdating = true;
      }


      return { ...prev, items: items }
    })
    let sendData = {
      bookId: bookId,
      product_type: bookType,
      action: action
    }
    fetch(apiBaseURL + apiURL.updateProduct, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
        'utoken': userData.token,

      },
      body: JSON.stringify(sendData)
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result)

        if (result.status == "1") {

          setCartData((prev) => {
            return {
              ...prev,
              items: result.cart_items?.map(item => ({ ...item, isUpdating: false })),
              total: result.Total_MRP,
              discount: result.Total_Discount,
              subtotal: result.SubTotal
            }
          });
        } else if (result.status == "0") {
          // show error

        }
      })
      .catch((error) => {
        console.log(error)
        //  show error
      });

  }
  function removeProduct(bookId, bookType, index) {

    if (!userData.loginStatus) {
      removeItemFromCart(bookId, bookType)
      return;
    }
    setCartData((prev) => {
      let items = [...prev.items];

      if (items[index] != undefined && items[index].ProductId == bookId) {
        items[index].isUpdating = true;
      }


      return { ...prev, items: items }
    })
    const sendData = {
      bookId: bookId,
      product_type: bookType,
    }
    fetch(apiBaseURL + apiURL.deleteFromCart, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
        'utoken': userData.token,

      },
      body: JSON.stringify(sendData)
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result)

        if (result.status == "1") {

          setCartData((prev) => {
            return {
              ...prev,
              items: result.cart_items?.map(item => ({ ...item, isUpdating: false })),
              total: result.Total_MRP,
              discount: result.Total_Discount,
              subtotal: result.SubTotal
            }
          });
          setCartItemNum(result.Total_Items)

        } else if (result.status == "0") {
          if (result.Message == "Cart Empty") {
            setCartData((prev) => {
              return {
                ...prev,
                items: [],
                total: 0,
                discount: 0,
                subtotal: 0
              }
            });
            setCartItemNum(0)
          }
          // show error
        }
      })
      .catch((error) => {
        console.log(error)
        //  show error
      });

  }
  // let boardName = "CBSE", boardId = queryString.get('bid')?queryString.get('bid'):'1';
  //   if(boardId=="1"){
  //     boardName = "cbse";
  //   } else if(boardId=="2"){
  //     boardName = "icse";

  //   } else if(boardId=="3"){
  //     boardName = "state";

  //   } else if(boardId=="4"){
  //     boardName = "ncert";

  //   } else if(boardId=="5"){
  //     boardName = "games";

  //   } 

  const { front_image, back_image, product_pdf, product_title, video_link, Publisher, Binding, Author, Language, isbn, pre_order, pre_orderTxt, productId } = bookData.data;

  const { paperbook_status, eBook_status, InteractiveEbook_status, CD_status, game_status, paperbook_ebook_status, category_title, categoryId, slug, catSlug } = bookData.data;
  const { price, bookMrp, description, bookPoint, weigth, bookType, isAddedToCart, isInWishlist, perDis, bookStock } = bookData.selectedVarient;

  const imgArray = [front_image];
  const videoLink = [video_link];
  if (back_image && back_image != "") {
    imgArray.push(back_image)
  }
  return (

    <div className="App">
      {seoData.isDone &&
        <Helmet>
          <title>{seoData.title} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.ogTags?.og_title} />
          <meta property="og:description" content={seoData.ogTags?.og_description} />
          <meta property="og:url" content={seoData.ogTags?.og_url} />
          <meta property="og:image" content={seoData.ogTags?.og_image_url} />

          <meta name="twitter:site" content="@rachnasagargrp" />
          <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
          <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
          <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header />
      <Navigation />
      <div className="discount-border"></div>
      <Container>
        <Row>
          <Col className="right-login">
            <div className="walls-btn">
              {paperbook_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.pBook) }} className={bookType == BOOK_VARIENTS.pBook ? "walls-btn-selected" : ""} > Paperback </span>
              }
              {eBook_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.eBook) }} className={bookType == BOOK_VARIENTS.eBook ? "walls-btn-selected" : ""}> Ebook </span>
              }
              {InteractiveEbook_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.ieBook) }} className={bookType == BOOK_VARIENTS.ieBook ? "walls-btn-selected" : ""}> Interactive e-Books </span>
              }
              {CD_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.cdDisk) }} className={bookType == BOOK_VARIENTS.cdDisk ? "walls-btn-selected" : ""}> CD </span>
              }
              {game_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.gBook) }} className={bookType == BOOK_VARIENTS.gBook ? "walls-btn-selected" : ""}> Game </span>
              }
              {paperbook_ebook_status == "1" &&
                <span onClick={() => { selectVarient(BOOK_VARIENTS.peBook) }} className={bookType == BOOK_VARIENTS.peBook ? "walls-btn-selected" : ""}> Paperbook+Ebook </span>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>



                {catSlug == 'memoirs' ?
                  <>
                    <li className="breadcrumb-item"><Link to={"/living-for-change"}>{category_title}</Link></li>
                    {/* <li className="breadcrumb-item active" aria-current="page">Product detail</li> */}
                    <li className="breadcrumb-item active" aria-current="page"><span style={{ fontSize: 17 }}>{product_title}</span></li>
                  </>
                  :
                  <>
                    <li className="breadcrumb-item"><Link to={"/books/" + catSlug}>{category_title}</Link></li>
                    {/* <li className="breadcrumb-item active" aria-current="page">Product detail</li>  */}
                    <li className="breadcrumb-item active" aria-current="page"><span style={{ fontSize: 17 }}>{product_title}</span></li>
                  </>
                }






              </ol>
            </nav>
          </Col>
        </Row>
        {bookData.isLoading ?
          <div style={{ position: 'relative', height: '200px' }}>
            <Loader width="80px" height="80px" backgroundColor="transparent" />
          </div> :
          <Row>
            <Col md={4}>
              <div>
                <ZoomImg imgArray={imgArray} pdfUrl={product_pdf} videoLink={video_link} />
                <p className='mt-2' dangerouslySetInnerHTML={{ __html: bookPoint }} />

              </div>
            </Col>






            <Col md={8}>
              <div className="walls">


                <h1> {product_title} </h1>

                <div className="price" style={{ fontWeight: 'bold' }}>Price :&nbsp; ₹ {price} &nbsp;
                  {perDis != "0" &&
                    <>
                      <strike>₹ {bookMrp}</strike> <span className="bookPer"> &nbsp;  ({perDis}% off)  </span>
                    </>
                  }
                </div>

                <div className=''>
                  {pre_order != '0' &&
                    <>
                      <span className='blinkPreOrder'>{pre_orderTxt}</span>
                    </>
                  }
                </div>

              </div>
              <div className='main-cart-section' >
                {showPopupCart.addingToCart ? //showPopupCart.addingToCart
                  <div className="cart-width" style={{ position: 'relative', height: '52px', display: 'inline-block' }}>
                    <Loader width="40px" height="40px" backgroundColor="transparent" />
                  </div> :
                  <div>
                    {isAddedToCart ?
                      <Link to="/orderDetail"> <button className="cart-width">Go To Cart</button></Link> :
                      <>
                        {bookStock != "0" ?
                          <button onClick={addToCart} className="cart-width">ADD TO CART</button> :
                          <button className="cart-width" style={{ cursor: 'not-allowed' }}>OUT OF STOCK</button>
                        }
                      </>
                    }
                  </div>
                }
                {bookStock != "0" &&
                  <div>
                    <Link to="/Profile/buyNow" state={{ bookId: bookData.data.productId, type: bookData.selectedVarient.bookType }}>
                      <button className="buyNow">BUY NOW</button>
                    </Link>
                  </div>
                }
                <span className="heart">
                  {wishlistLoading ?
                    <div className="" style={{ position: 'relative', height: '18px', display: 'inline-block' }}>
                      <Loader width="16px" height="16px" backgroundColor="transparent" />
                    </div> :
                    <span onClick={() => { addToWishlist(isInWishlist ? "remove" : 'add') }} style={{ cursor: "pointer" }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="_1vD2j _2_JeV" data-hook="wishlist-button-icon">
                        <path style={{ fill: isInWishlist ? "red" : "#ffffff" }} d="M3.898 12.17c1.334 2.325 7.355 7.092 8.06 7.321-.047-.011-.041-.012-.111.027.05-.028.123-.072.211-.126.21-.128.48-.3.778-.498a42.534 42.534 0 002.456-1.76c2.284-1.765 4.016-3.487 4.804-4.95.93-1.781 1.159-3.537.6-4.954-.355-1.079-1.253-1.963-2.446-2.467-1.938-.748-4.457.162-5.845 2.07l-.397.546-.406-.538c-1.398-1.85-3.743-2.784-5.777-2.067-1.16.361-2.027 1.265-2.531 2.59-.55 1.391-.317 3.146.604 4.805z" stroke="#000" fill="none" fillRule="evenodd"></path>
                      </svg>
                    </span>
                  }
                </span>

              </div>


              <Accordion defaultActiveKey="0">
                {bookData.detail.digital_product_info_status == "1" && (bookType == BOOK_VARIENTS.eBook || bookType == BOOK_VARIENTS.ieBook || bookType == BOOK_VARIENTS.peBook) &&
                  <Accordion.Item eventKey="0">
                    <div className='bor mt-1'>
                      <Accordion.Header className="acoo-hedi"> Digital Product Info </Accordion.Header>
                      <Accordion.Body>
                        {bookData.detail?.digital_product_info}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                }
                {bookData.detail.digital_term_condition_status == "1" &&
                  <Accordion.Item eventKey="1">
                    <div className='bor mt-1'>
                      <Accordion.Header> Digital Terms and Conditions </Accordion.Header>
                      <Accordion.Body>
                        {bookData.detail?.digital_term_condition}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                }
                {bookData.detail.additional_services_status == "1" &&
                  <Accordion.Item eventKey="2">
                    <div className='bor mb-2'>
                      <Accordion.Header> Additional Services </Accordion.Header>
                      <Accordion.Body>
                        {bookData.detail?.additional_services}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                }
              </Accordion>
              <Row className="m-2">
                {bookType != BOOK_VARIENTS.eBook && bookType != BOOK_VARIENTS.ieBook &&
                  <>
                    <Col md={3} sm={6} className="mb-2">
                      <div className="box-bold"> Delivery </div>
                    </Col>
                    <Col md={9} sm={6} className="mb-2">
                      Delhi/NCR - Approx 3 - 4 working days
                      Rest of India - Approx 5 - 8 working days
                    </Col>
                  </>
                }

                {Publisher != "" &&
                  <>
                    <Col md={3} sm={6} className="mb-2">
                      <div className="box-bold"> Publisher </div>
                    </Col>
                    <Col md={9} sm={6} className="mb-2">
                      <b>{Publisher}</b>
                    </Col>
                  </>
                }
                <Col md={3} sm={6} className="mb-2">
                  <div className="box-bold"> Description</div>
                </Col>
                <Col md={9} sm={6} className="mb-2">
                  {Binding != "" && (bookType == BOOK_VARIENTS.pBook || bookType == BOOK_VARIENTS.pBook) &&
                    <div className='mb-2'> <b>Binding : </b> {Binding}</div>
                  }
                  {Author != "" &&
                    <div className='mb-2'><b>Author : </b> {Author}</div>
                  }
                  {Language != "" &&
                    <div className='mb-2'><b> Language : </b> {Language}</div>
                  }
                  {isbn != "" &&
                    <div className='mb-2'><b>ISBN : </b> {isbn}</div>
                  }
                  {weigth &&
                    <div className='mb-2'><b> Weight : </b> {weigth}</div>
                  }

                </Col>

                {/* <Col md={12} className="mb-2">
                  <b>Video:</b> &nbsp;
                  {videoLink != "" &&
                    <VideoPopup video_link={videoLink} />
                  }
                </Col> */}




                {(bookType == BOOK_VARIENTS.eBook || bookType == BOOK_VARIENTS.ieBook || bookType == BOOK_VARIENTS.peBook) &&
                  <p> <b> Supported Device: Android - </b> (<a target="_blank" href='https://play.google.com/store/apps/details?id=com.rachnasagar'> Install "Rachna Sagar DigiText" </a>) <br />
                    <b className='higlight'> * Valid for one year from date of purchasing and can be accessed on only ONE registered Android device by
                      registering your e-mail . </b>
                    <b className='higlight'> * The eBook content can’t be downloaded or printed. </b>
                  </p>

                }

              </Row>
            </Col>

          </Row>
        }
        {suggestedBooks.length != 0 &&
          <SuggestedBooks suggestedBooks={suggestedBooks} type={ProductType} />
        }
        <div className="bukBox">
          <Row>
            <Col>
              <Tabs defaultActiveKey="Description" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="Description" title="Description">
                  {/* <p>Together With NEP 2020 Based Synergy  Learning And Activity Combo (5 IN 1) English Mathematics Science ,Social Studies , GK & Life Skills Book For Class 5 (Semester 2)</p> */}
                  <p dangerouslySetInnerHTML={{ __html: description }} />
                </Tab>
                <Tab eventKey="Reviews" title="Reviews">
                  <div className="tab-heading">
                    <h2> Customer Review </h2>
                    <Row>
                      <Col md={8} sm={12}>
                        <div className="rating_tab">
                          <h4>Rating breakdown</h4>
                          <Row>
                            <Col>
                              <div className="progress_tab">
                                5 &nbsp; <span className="fa fa-star checked"> </span>
                              </div>
                              <div className="progress_tab">
                                4 &nbsp; <span className="fa fa-star checked"> </span>
                              </div>
                              <div className="progress_tab">
                                3 &nbsp; <span className="fa fa-star checked"> </span>
                              </div>
                            </Col>
                            <Col>
                              <div className="progress_tab">
                                <progress id="file" value="100" max="100"> 100% </progress>
                              </div>
                              <div className="progress_tab">
                                <progress id="file" value="80" max="100"> 80% </progress>
                              </div>
                              <div className="progress_tab">
                                <progress id="file" value="60" max="100"> 60% </progress>
                              </div>
                            </Col>
                            <Col>
                              <div className="progress_tab">
                                {reviewData.reviewStars.five_stars}
                              </div>
                              <div className="progress_tab">
                                {reviewData.reviewStars.four_stars}
                              </div>
                              <div className="progress_tab">
                                {reviewData.reviewStars.three_stars}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="rating_tab">
                          <Link to={"/profile/writeReview/" + productId} > <Button variant="secondary">Write a review</Button> </Link>
                        </div>
                      </Col>
                      <Col md={12} sm={12}>
                        {reviewData.reviewList.map((item) => {
                          let stars = [];
                          for (let i = 0; i < item.star; i++) {
                            stars.push(<span className="fa fa-star review-cos"></span>)
                          }
                          return (
                            <div className="reviews-section">
                              <h5>{item.Name}</h5>
                              {stars}
                              <span className="review-hedi"> {item.headline} </span>
                              <p>{item.review} </p>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Container>
      {
        relatedBooks.length != 0 &&
        <RelatedBooks relatedBooks={relatedBooks} type={ProductType} />
      }
      {
        showPopupCart.status &&
        <CartPopup title="Cart" closePopup={() => { setPopupCart(false) }}>

          {cartData.items.map((item, index) => {

            return (
              <Row key={index.toString()}>
                <Col md={3} sm={3} xs={3}>
                  <div className="rightcart">
                    <img src={item.Image} />
                  </div>
                </Col>

                <Col md={9} sm={9} xs={9}>
                  <Row className="mb-2">
                    <Col md={12}>
                      <div className="rightcart1">
                        <h6> {item.Product_Title.substring(0, 30)}</h6>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="rightcart1">
                        <h6>Product Type : {item.Product_Type_Title}</h6>
                      </div>
                    </Col>
                    <Col md={4} sm={12} xs={4}>
                      <div className="rightcart">
                        <div className="right-price">₹ {item.Total_Price}</div>
                      </div>
                    </Col>
                    <Col md={4} sm={3} xs={3}>
                      <div className={(item.Product_Type != BOOK_VARIENTS.eBook && item.Product_Type != BOOK_VARIENTS.ieBook) ? "right-number" : ""}>
                        {item.isUpdating ?
                          <div style={{ position: 'relative', height: "40px", width: '100%' }}>
                            <Loader width='20px' height='20px' />
                          </div> :
                          <>
                            {item.Product_Type != BOOK_VARIENTS.eBook && item.Product_Type != BOOK_VARIENTS.ieBook &&
                              <>
                                <span className="minus-right" onClick={() => { updateProduct(item.ProductId, item.Product_Type, 'remove', index) }}>-</span>
                                <input type="submit" value={item.Quantity} />
                                <span className="plus-right" onClick={() => { updateProduct(item.ProductId, item.Product_Type, 'add', index) }}>+</span>
                              </>
                            }
                          </>
                        }
                      </div>
                    </Col>
                    {!item.isUpdating &&
                      <Col md={4} sm={4} xs={4} >
                        <div className="rempty-cart" onClick={() => { removeProduct(item.ProductId, item.Product_Type, index) }}><i className="fa fa-trash" aria-hidden="true"></i></div>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>
            )
          })}
          {cartData.items.length == 0 ?
            <>
              {cartData.isLoading ?
                <div>Loading...</div> :
                <div>Cart is Empty</div>
              }
            </>
            :
            <div className="right-foot">
              <div><span className='cart_total'>Total</span> <span className='rupes_cart'> : ₹ {cartData.total} </span> </div>
              <div><span className='cart_total'>Discount</span> <span className='rupes_cart'> : ₹ {cartData.discount} </span> </div>
              <div><span className='cart_total'>Subtotal</span> <span className='rupes_cart'> : ₹ {cartData.subtotal} </span> </div>
              <hr />
              <Link to="/orderDetail"> <button className="view-cart"> View Cart </button> </Link>
            </div>

          }
        </CartPopup>
      }
      {
        error.status &&
        <Error msg={error.msg} cancle={removeError} fun={reloadBookDetail} />
      }
      <Footer />
    </div >
  );
};
export default Products;


