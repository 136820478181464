import React, { useState, useMemo, useRef, createRef, useEffect, useCallback } from "react";
import zoomCss from './zoom.module.css';
import Pdfimg from "../images/pdf-img.png";
import Videoimg from "../images/video-img.jpg";
import Modal from "react-modal";

// Modal styling - ensure it's large enough
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw', // Adjust as needed
        height: '80vh', // Adjust as needed
        padding: 0, // Remove padding
        overflow: 'hidden', // Prevent scrolling
    },
};

export default function ZoomImg({ imgArray, pdfUrl, videoLink }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Function to open modal
    const openModal = () => {
        setModalIsOpen(true);
    };

    // Function to close modal
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const [currentImg, setCurImg] = useState(imgArray[0]);

    const zoomImg = useRef(null);
    const zoomContainer = useRef(null);
    const lensBox = useRef(null);


    useEffect(() => {

        if (zoomImg.current != null) {
            zoomImg.current.addEventListener("mousemove", startZoom);
            zoomImg.current.addEventListener("mouseout", stopZoom);
        }
        return () => {
            // console.log('removed')
            if (zoomImg.current != null) {
                zoomImg.current.removeEventListener("mousemove", startZoom)
                zoomImg.current.removeEventListener("mouseout", stopZoom)
            }
        }

    }, [currentImg])

    function startZoom(event) {

        if (zoomImg != null && zoomContainer.current != null && lensBox.current != null) {
            // console.log(currentImg)
            let zoomImgCoord = zoomImg.current.getBoundingClientRect();
            let zoomContainerCoord = zoomContainer.current.getBoundingClientRect();
            let lensBoxCoord = lensBox.current.getBoundingClientRect();
            // let x = zoomImgCoord.x - lensBoxCoord.x
            // console.log(zoomImgCoord)
            // console.log(zoomImg.current.width, zoomImg.current.height)
            // console.log(lensBoxCoord)
            // console.log(currentImg)
            let xRatio = zoomContainerCoord.width / lensBoxCoord.width;
            let yRatio = zoomContainerCoord.height / lensBoxCoord.height;
            let x = event.x - zoomImgCoord.x //- lensBoxCoord.x;
            let y = event.y - zoomImgCoord.y //- lensBoxCoord.y;

            let posX = x - lensBoxCoord.width / 2;
            let posY = y - lensBoxCoord.height / 2;


            if (x - lensBoxCoord.width / 2 <= 0) {
                // lensBox.current.style.left = `${0}px`
                posX = 0;
            } else if (x + lensBoxCoord.width / 2 >= zoomImgCoord.width) {
                // lensBox.current.style.left = `${zoomImgCoord.width-lensBoxCoord.width}px`
                posX = zoomImgCoord.width - lensBoxCoord.width;
            }
            if (y - lensBoxCoord.height / 2 <= 0) {
                // lensBox.current.style.top = `${0}px`
                posY = 0;
            } else if (y + lensBoxCoord.height / 2 >= zoomImgCoord.height) {
                // lensBox.current.style.top = `${zoomImgCoord.height-lensBoxCoord.height}px`
                posY = zoomImgCoord.height - lensBoxCoord.height
            }
            zoomImg.current.querySelector("img").style.opacity = '0.5'
            lensBox.current.style.left = `${posX}px`;
            lensBox.current.style.top = `${posY}px`;

            lensBox.current.style.backgroundImage = `url("${currentImg}")`
            lensBox.current.style.backgroundSize = `${zoomImgCoord.width}px ${zoomImgCoord.height}px`;
            lensBox.current.style.backgroundPosition = `-${posX}px -${posY}px`;
            lensBox.current.style.opacity = `1`;
            lensBox.current.style.visibility = `visible`;

            zoomContainer.current.style.backgroundImage = `url("${currentImg}")`
            zoomContainer.current.style.backgroundSize = `${zoomImgCoord.width * xRatio}px ${zoomImgCoord.height * yRatio}px`;
            zoomContainer.current.style.backgroundPosition = `-${posX * xRatio}px -${posY * yRatio}px`;
            zoomContainer.current.style.opacity = `1`;
            zoomContainer.current.style.visibility = `visible`;
            // console.log(currentImg);



            // console.log(x, event.x)
        }
    }
    function stopZoom() {
        if (zoomImg != null && zoomContainer.current != null && lensBox.current != null) {
            zoomImg.current.querySelector("img").style.opacity = '1'
            lensBox.current.style.opacity = `0`;
            lensBox.current.style.visibility = `hidden`;
            zoomContainer.current.style.opacity = `0`;
            zoomContainer.current.style.visibility = `hidden`;
        }


        // zoomImg.current.querySelector("img").style.opacity = '1'

    }

    // console.log(imgArray)

    return (

        // useMemo(()=>
        <div >
            <div className={zoomCss.mainImg} >
                <div ref={zoomImg}>
                    <img src={currentImg} />
                    <div className={zoomCss.lensBox} ref={lensBox} />
                </div>
                <div className={zoomCss.zoomContainer} ref={zoomContainer}>

                </div>

            </div>
            <div className="pdf-posi">
                {imgArray.map(item => {
                    let selected = false;
                    if (item == currentImg) {
                        selected = true;
                    }
                    return (
                        <span key={item} onClick={() => { setCurImg(item) }} className={`${zoomCss.bottomImgBox} ${!selected ? zoomCss.notSelected : zoomCss.selected}`} >
                            <img src={item} />
                        </span>
                    )
                })}
                {pdfUrl != "" &&
                    <span className="pdf-img">
                        <a href={pdfUrl} target="_blank">
                            <img src={Pdfimg} />
                        </a>
                    </span>
                }
                {/* Video Thumbnail (conditionally rendered) */}
                {videoLink && videoLink !== "" && (
                    <span className="mt-3">
                        {/* <img
                            src="https://via.placeholder.com/150" // Replace with your video thumbnail or dynamic URL
                            alt="Video Thumbnail"
                            style={{ cursor: 'pointer', width: '100%' }}
                            onClick={openModal} // Open modal on click
                        /> */}
                        {/* <i className="fa fa-youtube-play" onClick={openModal} style={{ cursor: 'pointer' }}></i> */}
                        <span className="pdf-img">
                            <img src={Videoimg} onClick={openModal} style={{ cursor: 'pointer' }} />
                        </span>
                    </span>
                )}

                {/* Modal to display video */}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Video Player"
                >
                    <button onClick={closeModal} style={{ position: 'absolute', top: 10, right: 10 }}>Close</button>
                    <div style={{ width: '100%', height: '100%' }}>
                        {videoLink ? (
                            <iframe
                                width="100%"
                                height="100%"
                                src={videoLink} // YouTube embed link
                                title="YouTube Video Player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <p>No video available</p>
                        )}
                    </div>
                </Modal>
            </div>

            {/* {console.log("dfksdf-->")} */}
        </div>
        // )
    )
}
